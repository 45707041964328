import React, {useState} from "react"
import Home from "./home"
import Home1 from "./home1"

const Index = () => {
    const [drag, setDrag] = useState(1)
    const [click, setClick] = useState(0)

    function animationDrag(e) {
        setDrag(e)
    }

    function animationClick(e) {
        setClick(e)
    }
    const handleOnDragStart = e => {
        const dragImg = new Image(0,0);
        e.dataTransfer.setDragImage(dragImg, 0, 0)
    }
    return (<div className="parent">
            <div draggable="true"
                className={`${drag !== 1 && click === 1 && 'active-home222'} home2 ${drag !== 1 && click !== 1 && 'active-home2'} ${drag !== 2 && click === 2 && 'active-home22'}`}
                onDrag={(e) => animationDrag(2)}
                onMouseDown={(e) => animationClick(2)}
                onMouseUp={(e) => animationClick(0)}
                 onDragStart={(e) => handleOnDragStart(e)}
            ><Home/></div>
            <div draggable="true"
                className={`${drag !== 2 && click === 2 && 'active-home111'} home1 ${drag !== 2 && click !== 2 && 'active-home1'} ${drag !== 1 && click === 1 && 'active-home11'}`}
                onDrag={(e) => animationDrag(1)}
                onMouseDown={(e) => animationClick(1)}
                onMouseUp={(e) => animationClick(0)}
                 onDragStart={(e) => handleOnDragStart(e)}
            ><Home1/></div>
        </div>
    )
}
export default Index