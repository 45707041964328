import './App.css';
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import HomePage from "./components/homePage";
import HomePage2 from "./components/homePage/home";

function App() {
  return (
    <Router forceRefresh={true}>
        <Switch>
            <Route exact path="/">
                <HomePage />
            </Route>
            <Route exact path="/home">
                <HomePage2 />
            </Route>
        </Switch>
    </Router>
  );
}

export default App;
