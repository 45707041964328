import React, {useState} from "react"
import {useHistory, withRouter} from "react-router-dom";
import ramaazSvg from "../../assets/svg/ramaaz.svg"
import "../../assets/css/home1.css"
import overlapLight from "../../assets/svg/overlapLight.svg";
import languageSvg from "../../assets/svg/languages.svg";

const Home = (props) => {
    const [showLang, setShowLang] = useState(false)
    let history = useHistory();

    function handleClick() {
        history.push("/home");
    }

    return (
        <div className="container-center-horizontal">
            <div className="ramaaz-website-homepage-2 screen">
                <div
                    onMouseLeave={() => setShowLang(false)} className={`mask-group-164`}>
                        <span className="abs-span" onMouseEnter={() => setShowLang(true)}>
                                            <img className="language" src={overlapLight} alt="light"/>
                                            <img className={`languages +  ${showLang ? "active-h" : "active-no"}`}
                                                 src={languageSvg}/>
                        </span>
                    <div className="mask-group-161">
                        <div className="translate-2">
                            <div className="g825">
                                <div className="g827">
                                    <div className="g829">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group-4826">
                    <div className="group-4825">
                        {/*<div className="overlap-group-1">*/}
                        {/*    <img className="path-16118" src="img/homepage/path-16118.png" alt="path-16118"/>*/}
                        {/*    <img className="ellipse-323-outline" src="img/homepage/ellipse-323---outline@1x.png"*/}
                        {/*         alt="ellipse-322"/>*/}
                        {/*</div>*/}
                    </div>
                    <img alt={"ramaaz"} className="path-161199" src={ramaazSvg}/>
                </div>
            </div>
        </div>)
}
export default withRouter(Home)

function G6839(props) {
    const {src, className} = props
    return <div className={`g839 ${className || ""}`}>
        <img className="path84" src={src} alt="path84"/>
    </div>
}