import React, {useState} from "react"
import "../../assets/css/home.css"
import overlapDark from "../../assets/svg/overlapDark.svg";
import {useHistory, withRouter} from "react-router-dom";
import languageSvg from "../../assets/svg/languages.svg";
import arrowSvg from "../../assets/svg/arrow.svg";
import ramaazDarkSvg from "../../assets/svg/ramaazDark.svg";

const Home_Page = (props) => {
    const [showLang, setShowLang] = useState(false)
    let history = useHistory();

    function handleClick() {
        history.push("/");
    }

    return (
        <>
            <div className="container-center-horizontal">
                <div className="ramaaz-website-homepage screen  ">
                    <div
                        onMouseLeave={() => setShowLang(false)}
                        className={`mask-group-164`}>
                        <span className="abs-span" onMouseEnter={() => setShowLang(true)}>
                            <img className="language" src={overlapDark}/>
                            <img className={`languages +  ${showLang ? "active-h" : "active-no"}`} src={languageSvg}/></span>
                    </div>
                    <div className="group-4819">
                        {/*<div className="group-4818">*/}
                        {/*    <div className="overlap-group-1">*/}
                        {/*        <img className={"path-16118"} src="img/homepage/path-16118-2.png" alt="path-16118"/>*/}
                        {/*        <img src="img/homepage/ellipse-322.png" alt="ellipse-322.png" className="ellipse-322"/>*/}
                        {/*        <div className="ellipse-322"/>*/}
                        {/*        <img src="ellipse-323---outline@1x.png" alt="ellipse-322.png"*/}
                        {/*             className="ellipse-323-outline"/>*/}
                        {/*    </div>*/}
                        {/*    <img className="path-16119" src="img/homepage/path-16119-2.png" alt="path-16119"/>*/}
                        {/*</div>*/}
                        <img src={ramaazDarkSvg} alt="dark"/>
                    </div>
                    {/*<span className="path-16135" src="img/homepage/path-16135-1.png" alt="path-16135"/>>*/}
                    <span className="path-16135">Business Development</span>
                    <span className="path-16134">Systems Solution</span>
                    {/*<img className="path-16134" src="img/homepage/path-16134-1.png" alt="path-16134"/>*/}
                    <img className="path2179" src={arrowSvg} alt="path2179"/>
                </div>
            </div>
        </>)
}
export default withRouter(Home_Page)